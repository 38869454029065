import React from "react";
import Navbar from "./Navbar";
import "../styles/navbar.css";
import Hero from "./hero";
import HomeAbout from "./HomeAbout";
import Green from "./Green";
import Why from "./Why";
import Elevate from "./Elevate";
import WhyTable from "./whyTable";
import Area from "./area";
import Footer from "./Footer";

const Home = () => { 
    return (
        <div className="home">
            <Navbar />
            <Hero />
            <HomeAbout />
            <Green/>
            <Why/>
            <Elevate/>
            <WhyTable/>
            <Area/>
            <Footer/>
        </div>
    );
}

export default Home;