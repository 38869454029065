import React from 'react';
import house from '../components/assets/images/a-beautiful-house-with-large-front-yard-opt.webp';
import '../styles/Makover.css';
import design from '../components/assets/images/design-consultation-service-indianapolis-in.webp'; 
 
function MakeoverDesign() {
    return (
        <div className='makeoverWrap'>
            <img src={design} alt="Blank" />

            <div className='makeoverContent'>
                <h1>Why Choose Our Design Consultation Service:</h1>
                <hr />
                <span>
Specialists in Color Selection: Discovering the perfect color scheme for your residential space in Indianapolis can feel overwhelming. That’s where our painting consultation expertise comes into play. Our paint consultants offer tailored recommendations to ensure your space reflects your style and personality. Moreover, to guarantee satisfaction, we offer up to two complimentary test patches, allowing you to visualize the final outcome.
<br />
Complimentary Test Patches: We don’t just tell you what works—we show you. Our painting consultation service includes up to two complimentary test patches, ensuring you’re entirely satisfied with your color selection before we start the job.
<br />
Personalized Design Consultations: We know the importance of getting it right the first time, that’s why our interior design color consultation ensures your home reflects your unique taste and style.
<br />
Expert Advice and Recommendations: At Picture Perfect Paint, we exclusively use high-quality Sherman Williams paint products for exceptional results. We provide expert advice and recommendations, ensuring the perfect color selection for every room in your Indianapolis, IN home.   
</span>
</div>
</div>

    );
}

export default MakeoverDesign;

