import React from 'react';
import '../styles/steps.css';

function Steps() {
    return (
        <div className='stepsWrap'>
            <h1>Steps On Our Project Approach</h1>
            <table className='steps'>
                <tr className='stepHead'>
                    <td>Set Up & Protect</td>
                    <td>Surface Prep</td>
                    <td>Apply Paint</td>
                    <td>Clean Up</td>
                    <td>Final Walk</td>
                </tr>
                <tr>
                    <td>We move and cover your flooring, as well as tape, and mask surfaces as needed</td>
                    <td>We repair small cracks and dents in drywall and caulk gaps and seams in woodwork</td>
                    <td>We will apply 1 – 2 finish coats based on the goals of your project. With color change we recommend 2 coats.</td>
                    <td>We will clean up daily and leave your home and property as we found it. Except for the beautiful paint job.</td>
                    <td>Our Project Manager will walk around with you to ensure that the job is completed and expectations are met.</td>
                </tr>
            </table>
        </div>
    );
}

export default Steps;
