import React from 'react';
import ResidentialHero from './residential-hero';
import Navbar from './Navbar';
import WhyTable from './whyTable';
import Why from './Why';
import Footer from './Footer';
import Reuse from './reuse';
import Steps from './steps';
import Makeover from './Makeover';

const Residential = () => {
    return (
        <div>
            <Navbar />
            <ResidentialHero />
            <Reuse />
            <Steps />
            <Makeover />
            <Why />
            <WhyTable />
            <Footer />
        </div>
    );
};

export default Residential;
