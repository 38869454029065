import React from 'react';
import Navbar from './Navbar';
import Area from './area';
import AreaMap from './areaMap';
import Why from './Why';
import WhyTable from './whyTable';
import Footer from './Footer';

import '../styles/Area.css';

function AreasServed() {
    return (
        <div>
            <Navbar />
            <AreaMap />
            <Area />
            <WhyTable />
            <Why />
            <Footer />
        </div>
    );
}

export default AreasServed;