import React from "react";
import "../styles/Area.css";

const Area = () => {
    return(
        <div className="areaWrapper">
            <div className="areaContent">
                <div className="areaHead">
                        <h1>Areas We Serve</h1>
                    <h2>We know you have many options when choosing a painter in or near Indianapolis, IN, and we want to be your first choice. Give us a call!</h2>
                </div>
                <div className="areaList">
                    <ul>
                        <li>Indianapolis, IN</li>
                        <li>Greenwood, IN</li>
                        <li>Franklin, IN</li>
                        <li>Whiteland, IN</li>
                        <li>Bargersville, IN</li>
                        <li>Beech Grove, IN</li>
                        <li>Lawrence, IN</li>
                    </ul>
                    <ul>
                        <li>Noblesville, IN</li>
                        <li>Fishers, IN</li>
                        <li>Carmel, IN</li>
                        <li>Westfield, IN</li>
                        <li>Cicero, IN</li>
                        <li>Meridian Hills, IN</li>
                        <li>Brownsburg, IN</li>
                    </ul>
                    <ul>
                        <li>Zionsville, IN</li>
                        <li>Avon, IN</li>
                        <li>Plainfield, IN</li>
                        <li>Broad Ripple, IN</li>
                        <li>Williams Creek, IN</li>
                        <li>Trafalgar, IN</li>
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default Area;