import React from 'react';
import Navbar from './Navbar';
import WhyTable from './whyTable';
import Why from './Why';
import Footer from './Footer';
import CarpentryHero from './carpentry-hero';
import Reuse from './reuse';
import ReuseCarpentry from './ReuseCarpentry';
import MakeoverCarpentry from './MakeoverCarpentry';
import Estimate from './Estimate';

const Carpentry = () => {
    return (
        <div>
            <Navbar />
            <CarpentryHero />
            <ReuseCarpentry />
            <MakeoverCarpentry />
            <Estimate />
            <WhyTable />
            <Why />
            <Footer />
        </div>
    );
};

export default Carpentry;
