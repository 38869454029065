import React from 'react';
import '../styles/commercial-hero.css';
import { Link } from 'react-router-dom';

function CarpentryHero() {
    return (
        <div>
            <div className='commercial-hero'>
                <div className='commercial-hero-text'>
                    <h1>Carpentry Repairs</h1>
                    <br />
                    <Link to='/contact' className="button-link">GET A FREE ESTIMATE</Link>
                </div>
            </div>
        </div>
    );
}

export default CarpentryHero;
