import React from 'react';
import house from '../components/assets/images/a-beautiful-house-with-large-front-yard-opt.webp';
import '../styles/Makover.css';
 
function Makeover() {
    return (
        <div className='makeoverWrap'>
            <img src={house} alt="Blank" />

            <div className='makeoverContent'>
                <h1>Discover Our Detail-Oriented Approach</h1>
                <hr />
                <span>
                At Picture Perfect Paint, we understand that a great paint job goes beyond just applying a fresh coat. That’s why we take the time to prepare your space, performing necessary repairs and drywall work to ensure a flawless finish. We also offer deck staining and painting, giving you a complete residential painting solution. With each project, our residential painting contractors showcase their exceptional skills, precision, and meticulous attention to detail. Whether it’s your living room walls, your bedroom ceiling, or your exterior siding, we ensure a high-quality paint job that revives and refreshes your entire space.
                </span>
                <br />
                <br />
                <h1>Bring Your Home Back to Life!</h1>
                <span>
                Ready to transform your home into the elegant and vibrant space you’ve always envisioned? Trust Picture Perfect Paint, your professional provider of residential painting services in Indianapolis, IN. Our dedicated team is ready to add color, character, and charm to your home. Contact us today and let’s paint a picture-perfect future for your home together!
                </span>

            </div>

              
        </div>

    );
}

export default Makeover;

