import React from 'react';
import '../styles/residential-hero.css';
import { Link } from 'react-router-dom';

function ResidentialHero() {
    return (
        <div className='residential-wrapper'>
            <div className='residential-hero'>
                <div className='residential-hero-text'>
                    <h1>Premium Residential Painting</h1>
                    <Link to='/contact' className="button-link">GET A FREE ESTIMATE</Link>
                </div>
                <div className='residential-hero-image'></div>
            </div>
        </div>
    );
}

export default ResidentialHero;
