import React from 'react';
import '../styles/Deck-hero.css';
import { Link } from 'react-router-dom';

function DeckHero() {
    return (
        <div>
            <div className='deck-hero'>
                <div className='deck-hero-text'>
                    <h1>Deck and Fence Building</h1>
                    <Link to='/contact' className="button-link">GET A FREE ESTIMATE</Link>
                </div>
            </div>
        </div>
    );
}

export default DeckHero;
