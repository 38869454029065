import React from 'react';
import '../styles/commercial-hero.css';
import { Link } from 'react-router-dom';
import '../styles/why.css';

function CommercialHero() {
    return (
        <div>
            <div className='commercial-hero'>
                <div className='commercial-hero-text'>
                    <h1>Commercial</h1>
                    <Link to='/contact' className="button-link">GET A FREE ESTIMATE</Link>
                </div>
            </div>
        </div>
    );
}

export default CommercialHero;
