import React from "react";
import "../styles/ha.css";

const ReuseCarpentry = () => {
    return (
        <div className="HaWrap">
        <div className="HaReuse">
        <div className="HaSub">
            <h2>Detail. Expertise. Reliability.</h2>
            <hr></hr>
        </div>
            <h1 className="head">Get Complete Gutter Cleaning and Siding Repairs in Indianapolis, IN</h1>
        </div>
        <div className="homeAbout">
            <div className="HaContent">
                <h2>A comprehensive approach to home care is what we strive for at Picture Perfect Paint.</h2>
                <span>
                Is your siding showing signs of damage? Are your gutters overflowing during rainstorms? These signs could indicate that your home needs professional attention. Picture Perfect Paint, your trusted local partner in Indianapolis, IN, offers top-quality gutter cleaning and siding repair services that aim to safeguard your home. Our experienced professionals use the latest techniques and the highest quality materials to ensure your home is repaired correctly and look its best. We guarantee your satisfaction with our work and offer competitive pricing. Contact us today to schedule an appointment.
                </span>  
            </div>
       </div>
        </div>
    );
}

export default ReuseCarpentry;