import Navbar from './Navbar';
import Footer from './Footer';
import Why from './Why';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import '../styles/Contact.css';
function Contact() {
    const [successMessage, setSuccessMessage] = useState('');

    const { register, handleSubmit, formState: errors } = useForm();
    const onSubmit = (data, e) => {
        console.log(data);
        emailjs.sendForm('service_8q4h2eq', 'template_rnu9k7q', e.target, '0-674yjFm0tHjLTaU')
            .then((result) => {
                console.log(result.text);
                setSuccessMessage('Message sent successfully! We will get back to you as soon as possible.')
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className='FormWrap'>
                <h2 className='formHead'>Get in Touch With Us</h2>
                <span className='formSub'>For more information about how we can help you with your next remodeling project, call us today!</span>
                <form className="contact" onSubmit={handleSubmit(onSubmit)}>
                        <label>
                            First Name:
                            <input type="text" name="first_name" {...register('first_name', { required: true })} placeholder='John' />
                        </label>
                        <label>
                            Last Name:
                            <input type="text" name="last_name" {...register('last_name', { required: true })} placeholder='Smith' />
                        </label>
                        <label>
                            Email:
                            <input type="email" name="from_email" {...register('from_email', { required: true })} placeholder="john@smith.com" />
                        </label>
                        <label>
                            Phone Number:
                            <input type="tel" name="phone_number" {...register('phone_number', { required: true })} placeholder="123-456-7890" />
                        </label>
                    <label>
                        Message:
                        <input type="text" name="message" {...register('message', { required: true })} placeholder="Tell us how we can help!" />
                    </label>
                    {errors.message && 'A message is required'}
                    <input className="submit" type="submit" value="Submit" />
                    {successMessage && <p className='success-message'>{successMessage}</p>}
                </form>
            </div>

            <Why />
            <Footer />
        </div>
    );
}

export default Contact;