import React from "react";
import "../styles/hero.css";
import Form from "./form";
const Hero = () => {

    function transitionToPage(href) {
        document.querySelector('.swirl').style.display = 'block';
        document.querySelector('.swirl').addEventListener('animationend', function() {
          window.location.href = href;
        });
      }

      
    return (
        <div className="hero">
            <h1>We Treat Your Home Like It’s Our Own</h1>
            <Form />
        </div>
    );
}

export default Hero;