import React from 'react';
import house from '../components/assets/images/a-beautiful-house-with-large-front-yard-opt.webp';
import '../styles/Makover.css';
import carp from '../components/assets/images/carp.webp'; 
 
function MakeoverCarpentry() {
    return (
        <div className='makeoverWrap'>
            <img src={carp} alt="Blank" />

            <div className='makeoverContent'>
                <h1>Protection, Maintenance, and Prevention – Your Home’s Best Defense</h1>
                <hr />
                <span>
                At Picture Perfect Paint, we believe in a comprehensive approach to home care. Our siding repair services include thorough inspections, expert repair work, and a commitment to matching and blending for a seamless finish. But we don’t stop at repairs. Our team also ensures the protection and maintenance of your siding, extending its lifespan and curb appeal. Additionally, our gutter cleaning services provide not just thorough cleaning, but also careful inspection and maintenance. Our aim is to prevent water damage, protect the integrity of your home’s structure, and maintain the effectiveness of your gutter system.                </span>
                <br />
                <br />
                <h1>Our Carpentry Services</h1>
                <span>
                For your residential home’s exterior painting needs, we understand the importance of achieving professional results. Our comprehensive Exterior House Painting services encompass Power Washing, Exterior Painting, and Wood Repairs to ensure your home looks its best and is well-maintained.    
                </span>
            </div>

              
        </div>

    );
}

export default MakeoverCarpentry;

