import React from 'react';
import PWHero from './PW-hero';
import Navbar from './Navbar';
import Footer from './Footer';
import ReusePw from './ReusePw';
import MakeoverPW from './MakeoverPw';
import Estimate from './Estimate';
import Why from './Why';
import WhyTable from './whyTable';

function PowerWashing() {
    return (
        <div>
            <Navbar />
            <PWHero />
            <ReusePw />
            <MakeoverPW />
            <Estimate />
            <WhyTable />
            <Why />
            <Footer />
        </div>
    );
}

export default PowerWashing;
