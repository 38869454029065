import React from "react";
import "../styles/ha.css";

const ReuseDeck = () => {
    return (
        <div className="HaWrap">
        <div className="HaReuse">
        <div className="HaSub">
            <h2>Style, Function, Excellence.</h2>
            <hr></hr>
        </div>
            <h1 className="head">Top-notch Fence Installation Services in Indianapolis, IN</h1>
        </div>
        <div className="homeAbout">
            <div className="HaContent">
                <h2>Redefine outdoor living with Picture Perfect Paint.</h2>
                <span>Did you know that well-crafted decks and fences not only add to your home’s aesthetic appeal but also significantly increase its value? Picture Perfect Paint, your trusted local fence company in Indianapolis, IN, is here to help you enhance your property. We offer professional deck and fence installation services designed to create beautiful, functional spaces that complement your lifestyle. Our deck builders and fence contractors are known for their excellent craftsmanship and attention to detail, creating structures that enhance the beauty and functionality of your home.</span>  
            </div>
       </div>
        </div>
    );
}

export default ReuseDeck;