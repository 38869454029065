import React from "react";
import '../styles/navbar.css';
import { FaRegCalendar } from 'react-icons/fa';
import { FaFacebook, FaInstagram, FaGoogle } from 'react-icons/fa';
import { BottomNavigation } from "@mui/material";
import { IconButton } from "@mui/material";
import { GiHamburgerMenu } from 'react-icons/gi';
import { Menu } from "@mui/material";
import { MenuItem } from "@mui/material";
import { useState } from "react";
import { AiOutlineClose } from 'react-icons/ai';
import { Link } from "react-router-dom";
import { Popover } from '@mui/material';
import logo from '../components/assets/images/logo_new.2.png';

const Navbar = () => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [servicesAnchorEl, setServicesAnchorEl] = useState(null);
    const [resourcesAnchorEl, setResourcesAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const servicesOpen = Boolean(servicesAnchorEl);
    const resourcesOpen = Boolean(resourcesAnchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setServicesAnchorEl(null);
        setResourcesAnchorEl(null);
    };

    const handleServicesClick = (event) => {
        setServicesAnchorEl(event.currentTarget);
    };

    const handleResourcesClick = (event) => {
        setResourcesAnchorEl(event.currentTarget);
    };

    const handleServicesClose = () => {
        setServicesAnchorEl(null);
    };

    const handleResourcesClose = () => {
        setResourcesAnchorEl(null);
    }

    window.onscroll = function() {scrollFunction()};

function scrollFunction() {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        document.querySelector(".navbar").style.height = "220px";
        document.querySelector(".navbar").style.transition = "height 0.5s ease";
        document.querySelector(".links").style.transition = "height 0.1s ease";
        document.querySelector(".logo").style.display = "none";
        document.querySelector(".logo").style.marginTop = "20px";
        document.querySelector(".links").style.marginTop = "0px";
    } else {
        document.querySelector('.logo').style.display = "block";
        document.querySelector(".navbar").style.height = "350px";
        document.querySelector(".navbar").style.transition = " 0.3s ease";
        document.querySelector(".links").style.transition = "height 0.1s ease";
        document.querySelector(".logo").style.marginTop = "-100px";
        document.querySelector(".logo").style.height = "350px";
        document.querySelector(".logo").style.width = "350px";
        document.querySelector(".links").style.marginTop = "-70px";
        document.querySelector(".hamburger").style.marginTop = "-20px";
    }
}



    return (
        <div className="navbar">
            <div className="topNav">
            <div className="contact">
                <a href="tel:1-317-560-9263">IN (317) 560-9263</a>
                <Link to="/contact">
                    <span><FaRegCalendar/> Schedule an Appointment</span>
                </Link>
            </div>
            <div className="socials">
                <FaFacebook />
                <FaInstagram />
                <FaGoogle />
            </div>
            </div>
            <div className="bottomNav">
<div className="flex">
<img className="logo" src={logo} alt="logo">
                  </img>           
<div className="links">
    <Link to="/" className="link">Home</Link>
    <div className="dropDown">
        Services
        <div className="dropdown-content">
            <Link to="/residential" className="dropdown-links">Residential</Link>
            <Link to="/commercial" className="dropdown-links">Commercial</Link>
            <Link to="/powerwashing" className="dropdown-links">Power Washing</Link>
            <Link to="/deckFence" className="dropdown-links">Deck and Fence Building</Link>
            <Link to="/carpentry" className="dropdown-links">Carpentry</Link>
            <Link to="/design" className="dropdown-links">Design Consultation</Link>
        </div>
    </div>
    <div className="dropDown">
        Resources
        <div className="dropdown-content">
            <Link to="/areasServed" className="dropdown-links">Areas We Serve</Link>
            <Link to="/contact"className="dropdown-links">Contact</Link>
        </div>
    </div>
    {/* <Link to="/about" className="link">About</Link> */}


</div>
</div>
<div className="hamburger">
            <IconButton onClick={handleClick} style={{transition:'.3s'}}>
                {open ? <AiOutlineClose/> : <GiHamburgerMenu />}
            </IconButton>
            <Menu style={{ marginTop:'50.5px', padding:'0px', borderRadius:'30px'}}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <div className="menu" style={{ backgroundColor: '#36588f', width: '120rem', height: '500px', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center',
                                                color:'white'                                                       
                                                }}>
                    <MenuItem onClick={handleClose} style={{ fontFamily:'sans-serif', fontWeight:'bold', margin:'15px' }}><Link to="/">Home</Link></MenuItem>
                    <MenuItem onClick={handleServicesClick} style={{ fontFamily:'sans-serif', fontWeight:'bold',  margin:'15px'  }}>Services</MenuItem>
                    <Popover
                        className="popover"
                        anchorEl={servicesAnchorEl}
                        open={servicesOpen}
                        onClose={handleServicesClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Link to="/residential" className="dropdown-links">Residential</Link>
                        <Link to="/commercial" className="dropdown-links">Commercial</Link>
                        <Link to="/powerwashing" className="dropdown-links">Power Washing</Link>
                        <Link to="/deckFence" className="dropdown-links">Deck and Fence Building</Link>
                        <Link to="/carpentry" className="dropdown-links">Carpentry</Link>
                        <Link to="/design" className="dropdown-links">Design Consultation</Link>
                    </Popover>
                    <MenuItem onClick={handleResourcesClick} style={{ fontFamily:'sans-serif', fontWeight:'bold',  margin:'15px'      }}>Resources</MenuItem>
                    <Popover
                    className="popoverTwo"
                        anchorEl={resourcesAnchorEl}
                        open={resourcesOpen}
                        onClose={handleResourcesClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
            <Link to="/areasServed" className="dropdown-links">Areas We Serve</Link>
            <Link to="/contact"className="dropdown-links">Contact</Link>
                   </Popover>
                    {/* <MenuItem onClick={handleClose} style={{ fontFamily:'sans-serif', fontWeight:'bold',  margin:'15px'      }}>About</MenuItem> */}
                </div>
            </Menu>
        </div>
        </div>                                      
        </div>
    );
}

export default Navbar;