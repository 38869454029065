import React from 'react';
import fence from '../components/assets/images/a-newly-constructed-deck.webp';
import '../styles/Makover.css';
 
function MakeoverDeck() {
    return (
        <div className='makeoverWrap'>
            <img src={fence} alt="Blank" />

            <div className='makeoverContent'>
                <h1>Your Vision, Our Expertise: Beautiful Decks and Fences</h1>
                <hr />
                <span>
                At Picture Perfect Paint, we pride ourselves on our ability to bring your vision to life. Our services include custom deck design and construction, as well as fence design and installation. We only use quality materials, ensuring your deck or fence is not just stunning, but also durable. With our team of experienced fencing contractors, we promise to deliver a deck or fence that will be the envy of your neighborhood. Experience the perfect blend of functionality, durability, and aesthetics with Picture Perfect Paint.
                </span>
                <br />
                <br />
                <h1>Transform Your Outdoor Space</h1>
                <span>
                Ready to elevate your outdoor living experience and add value to your home? Trust Picture Perfect Paint, your premier fence installation service provider in Indianapolis, IN. Our dedicated team is ready to create a beautifully designed, highly functional deck or fence for your home. Call us today to start your transformation journey.
                </span>

            </div>

              
        </div>

    );
}

export default MakeoverDeck;

