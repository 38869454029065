import React from "react";
import '../styles/green.css';
import '../styles/why.css';
import Deck from "../components/assets/images/deck.webp";
import Residential from "./assets/images/a-beautiful-house-with-large-front-yard-opt.webp";
import Commercial from "./assets/images/commercial.webp";
import Pw from "./assets/images/PW.webp";
import Gutter from "./assets/images/gutter.webp";
import Fence from "./assets/images/IMG_2797.JPG";
import Design from "./assets/images/design-consultation-service-indianapolis-in.webp";
// import { Link } from "@mui/material";
import { Link } from "react-router-dom";


const Why = () => {
    return (
        <div className="whyWrapper">
            <div className="whyHeader">
                <div className="HaSub">
                    <h2>Why Pick Picture Perfect Paint Company?</h2>
                    <hr></hr>
                </div>
                <h1 className="head">From Residential to Commercial Solutions, We’ve Got You Covered!</h1>
            </div>

            <div className="whyContent">
                <div className="wrap">
                    <img src={Residential} />
                    <div className="whyHead">
                        <div className="HaSub">
                            <h2>Residential Painting Services</h2>
                            <hr></hr>
                        </div>
                        <h1 className="head">We bring life back to your home with our meticulous and transformative interior and exterior painting</h1>
                    </div>
                    <Link to='/residential' className="button-link">Learn More</Link>
                </div>

                <div className="wrap">
                    <img src={Commercial} />
                    <div className="whyHead">
                        <div className="HaSub">
                            <h2>Commercial Painting Services</h2>
                            <hr></hr>
                        </div>
                        <h1 className="head">Boost the ambiance and productivity of your workspace with our professional painting services.</h1>
                    </div>
                    <Link to='/commercial' className="button-link">Learn More</Link>
                </div>

                <div className="wrap">
                    <img src={Pw} />
                    <div className="whyHead">
                        <div className="HaSub">
                            <h2>Powerwashing Services</h2>
                            <hr></hr>
                        </div>
                        <h1 className="head">Improve your properties curb appeal with our effective and efficient power washing. </h1>
                    </div>
                    <Link to='/powerwashing' className="button-link">Learn More</Link>
                </div>
                <div className="wrap">
                    <img src={Fence} />
                    <div className="whyHead">
                        <div className="HaSub">
                            <h2>Fence Installation</h2>
                            <hr></hr>
                        </div>
                        <h1 className="head">Enhance your properties value and aesthetic appeal with our expertly designed and installed fence services.</h1>
                    </div>
                    <Link to='/deckFence' className="button-link">Learn More</Link>
                </div>
                <div className="wrap">
                    <img src={Gutter} />
                    <div className="whyHead">
                        <div className="HaSub">
                            <h2>Gutter Cleaning</h2>
                            <hr></hr>
                        </div>
                        <h1 className="head">Protect your home from potential water damage with our thorough gutter cleaning and maintenance services.</h1>
                    </div>
                    {/* <Link to='/residential' className="button-link">Learn More</Link> */}

                </div>
                                <div className="wrap">
                                    <img src={Design} />
                                    <div className="whyHead">
                                        <div className="HaSub">
                                            <h2>Design Consultation </h2>
                                            <hr></hr>
                                        </div>
                                        <h1 className="head">Enhance your home with our painting consultation service and experience the transformative power of color.</h1>
                                    </div>
                                    {/* <Link to='/design' className="button-link"><button className="whyButton">Learn More</button></Link> */}
                                </div>
            </div>
        </div>
    )
}

export default Why;