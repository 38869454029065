import React from 'react';
import DeckHero from './Deck-Hero';
import Navbar from './Navbar';
import Footer from './Footer';
import ReuseDeck from './ReuseDeck';
import MakeoverDeck from './MakeoverDeck';
import Estimate from './Estimate';
import WhyTable from './whyTable';
import Why from './Why';

function DeckFence() {
    return (
        <div>
            <Navbar />
            <DeckHero />
            <ReuseDeck />
            <MakeoverDeck />
            <Estimate />
            <WhyTable />
            <Why />
            <Footer />

        </div>
    );
}

export default DeckFence;
