import React from 'react';
import '../styles/design-hero.css';
import { Link } from 'react-router-dom';

function DesignHero() {
    return (
        <div>
            <div className='design-hero'>
                <div className='design-hero-text'>
                    <h1>Design Consultation</h1>
                    <Link to='/contact' className="button-link">GET A FREE ESTIMATE</Link>
                </div>
            </div>
        </div>
    );
}

export default DesignHero;
