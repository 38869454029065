import React from "react";
import "../styles/ha.css";

const Reuse = () => {
    return (
        <div className="HaWrap">
        <div className="HaReuse">
        <div className="HaSub">
            <h2>Revive. Refresh. Reside.</h2>
            <hr></hr>
        </div>
            <h1 className="head"> Premium Residential Painting Services in Indianapolis, IN</h1>
        </div>
        <div className="homeAbout">
            <div className="HaContent">
                <h2>Unleash the beauty of your home with our professional assistance.</h2>
                <span>Are you noticing peeling paint, stains on your walls, or an outdated color scheme? These are signs that your property may be crying out for a fresh coat of paint. Picture Perfect Paint is your trusted partner in Indianapolis, IN, for all your painting needs. We provide comprehensive painting services, from residential and commercial painting to additional services that help maintain and enhance your property’s value.</span>  
            </div>
       </div>
        </div>
    );
}

export default Reuse;