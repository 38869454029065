import React from "react";
import "../styles/footer.css";
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';


const Footer = () => {
    return(
        <div className="footerWrapper">
            <div className="contact-us">
                <h1 className="head">Contact Us</h1>
                <hr />
                    <h1>Primary Number:</h1>
                    <p>(317) 560-9263</p>
                    <br />
                    <h1>Cellphone Number:</h1>
                    <p>(317) 523-3197</p>
                    <br />
                    <h1>Email:</h1>
                    <p>pictureperfectpaintppp@gmail.com</p>
                    <br />
                    <h1>Business Hours:</h1>
                    <p>Monday - Friday: 9:00am - 5:00pm</p>
                    <p>Saturday -Sunday: Closed</p>
                    <br />
                    <h1>Address:</h1>
                    <p>Indiapolis, IN</p>
        </div>
        <div className="services-footer">
            <h1>Services</h1>
            <hr />
            <span>Residential</span>
            <span>Commercial</span>
            <span>Power Washing</span>
            <span>Deck & Fence Building</span>
            <span>Carpentry</span>
            <span>Design Consultation</span>
        </div>
        <div className="social-media">
            <h1>Connect</h1>
            <hr />
                <FaFacebook/>
                <FaInstagram/>
                <FaTwitter/>
        </div>
        </div>
    )
};

export default Footer;