import React from 'react';
import Navbar from './Navbar';
import CommercialHero from './commercial-hero';
import WhyTable from './whyTable';
import Why from './Why';
import Footer from './Footer';
import Reuse from './reuse';
import ReuseCom from './ReuseCom';
import MakeoverCom from './MakeoverCom';
import Estimate from './Estimate';

const Commercial = () => {
    return (
        <div>
            <Navbar />
            <CommercialHero />
            <ReuseCom />
            <Estimate />
            <MakeoverCom />
            <WhyTable />
            <Why />
            <Footer />
        </div>
    );
}

export default Commercial;
