import React from 'react';
import Navbar from './Navbar';
import WhyTable from './whyTable';
import Why from './Why';
import Footer from './Footer';
import Estimate from './Estimate';
import DesignHero from './DesignHero';
import MakeoverDesign from './MakeoverDesign';

function Design() {
    return (
        <div>
            <Navbar />
            <DesignHero />
            <MakeoverDesign />
            <Estimate />
            <WhyTable />
            <Why />
            <Footer />
        </div>
    );
}

export default Design;
