import React from "react";
import '../styles/elevate.css';
import Year from "../components/assets/images/Home-Advisor-1-year-175x100-Color-01.svg";
import Verified from "../components/assets/images/Home-Advisor-Screened-and-Approved-175x100-Color-01.svg";
import Elite from "../components/assets/images/Home-Advisor-Elite-Service-175x100-Color-01.svg";
import Top from "../components/assets/images/Home-Advisor-Top-Rated-175x100-Color-01.svg";

const Elevate = () => {
    return (
        <div className="elevateWrapper">
            <span>"Elevate Your Style"</span>
            <div className="elevateContent">
                <img src={Year} alt="Year"/>
                <img src={Verified} alt="Verified"/>
                <img src={Elite} alt="Elite"/>
                <img src={Top} alt="Top"/>
            </div>
        </div>
    );

}

export default Elevate;