import React from 'react';
import '../styles/estimate.css';

const Estimate = () => {
    return (
        <div className='estimateWrap'>
            <h1>Get Your Free Estimate!</h1>
            <a href="tel:1-317-560-9263" className='estimateBtn'>Call Now</a>        </div>
    );
};

export default Estimate;
