import React from "react";
import "../styles/ha.css";

const ReusePw = () => {
    return (
        <div className="HaWrap">
        <div className="HaReuse">
        <div className="HaSub">
            <h2>Cleaner. Brighter. Better.</h2>
            <hr></hr>
        </div>
            <h1 className="head">Superior Power Washing Services in Indianapolis, IN</h1>
        </div>
        <div className="homeAbout">
            <div className="HaContent">
                <h2>Unveil the hidden beauty of your property.</h2>
                <span>Is your home’s exterior showing signs of dirt accumulation, mold, or mildew? Are your deck or driveway looking dull and stained? These signs indicate that it’s time to give your outdoor spaces the deep clean they need. Picture Perfect Paint is here to help you with our top-notch cleaning solutions. Armed with state-of-the-art equipment and experienced technicians, we provide efficient and effective power washing services for both residential and commercial properties in and near Indianapolis, IN. Count on us to leave your exteriors spotless and pristine.</span>  
            </div>
       </div>
        </div>
    );
}

export default ReusePw;