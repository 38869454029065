import React from "react";
import "../styles/ha.css";

const ReuseCom = () => {
    return (
        <div className="HaWrap">
        <div className="HaReuse">
        <div className="HaSub">
            <h2>Fresh Paint. Fresh Perspectives.</h2>
            <hr></hr>
        </div>
            <h1 className="head">Exceptional Commercial Painting Services in Indianapolis, IN</h1>
        </div>
        <div className="homeAbout">
            <div className="HaContent">
                <h2>Revitalize your working space in no time!</h2>
                <span>Does your workspace look dull or show signs of wear? Chipped paint, fading colors, and dated decor may indicate it’s time to refresh your environment with new paint. Picture Perfect Paint is your local commercial painting company in Indianapolis, IN, equipped to transform any space with a fresh coat of paint. We provide professional painting services for a variety of establishments, including retail stores, offices, schools, healthcare facilities, hospitality venues, and churches. Our skilled commercial painters are adept at handling both interior and exterior painting tasks, ensuring your establishment portrays a vibrant, welcoming image.</span>  
            </div>
       </div>
        </div>
    );
}

export default ReuseCom;