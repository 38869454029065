import React from 'react';
import '../styles/PW-hero.css';
import { Link } from 'react-router-dom';

const PWHero = () => {
    return (
        <div>
            <div className='pw-hero'>
                <div className='pw-hero-text'>
                    <h1>Power Washing</h1>
                    <Link to='/contact' className="button-link">GET A FREE ESTIMATE</Link>
                </div>
                <div className='pw-hero-image'></div>
            </div>
        </div>
    );
};

export default PWHero;
