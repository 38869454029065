import React from "react";
import '../styles/green.css';
import Deck from "../components/assets/images/deck.webp";
import { Link } from "react-router-dom";


const Green = () => {
    return (
        <div className="greenWrapper">
            <div className="left">
            <div className="greenHead">
                <h1>Transforming Spaces and Enhancing Property Value</h1>
            </div>
            <div className="greenContent">
                <span className="top">At Picture Perfect Paint, our mission extends beyond mere aesthetics. We provide residential painting services that refresh your living spaces and commercial painting services that can invigorate your work environment.</span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <p>Looking to elevate the outdoors? We offer power washing services to deep clean your exterior surfaces and fence installation to redefine boundaries with style and substance. Siding showing signs of damage? Our siding repair services ensure seamless restoration. Gutters overflowing during rainstorms? We provide meticulous gutter cleaning services to safeguard your home’s structure and prevent water damage.</p>
            </div>
            <Link to="/contact" className="button-link">Work with us</Link>
            </div>
            <div className="right">
                <div className="greenImg">
                    <img src={Deck}/>
                    <div className="imgBack"></div>
                </div>
            </div>
        </div>

    )
}

export default Green;