import logo from './logo.svg';
import './App.css';
// import React from 'react';
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import Home from './components/Home';
// import About from './components/About';
import Residential from './components/Residential';
import Commercial from './components/commercial'
import Contact from './components/Contact';
// import Gallery from './components/Gallery';
import AreasServed from './components/AreasServed';
import PowerWashing from './components/PowerWashing';
import DeckFence from './components/deckFence';
import Carpentry from './components/Carpentry';
import Design from './components/Design';
import 'aos/dist/aos.css';
import React, { useEffect } from 'react';
import AOS from 'aos';
import ScrollToTop from './components/scroll';


function App() {
  
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true, // specifies whether animation should happen only once - while scrolling down
    });
    // This function runs when the component is unmounted
    return () => {
      AOS.refresh();
    };
  }, []);

  window.onload = function () {
    window.scrollTo(0, 0);
  };


  return (
    <div className="App">
      <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
       {/* <Route path="/about" element={<About />} /> */}
        <Route path="/residential" element={<Residential />} />
        <Route path="/commercial" element={<Commercial />} />
        <Route path='/powerwashing' element={<PowerWashing />} />
        <Route path='/deckFence' element={<DeckFence />} />
        <Route path='/carpentry' element={<Carpentry />} />
        <Route path='/design' element={<Design />} /> 
        <Route path="/areasServed" element={<AreasServed />} />
        <Route path="/contact" element={<Contact />} />


        {/* <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        
      */}
      </Routes>
      </Router>
    </div>
  );
}

export default App;
