import React from 'react';
import house from '../components/assets/images/a-beautiful-house-with-large-front-yard-opt.webp';
import '../styles/Makover.css';
import PW from '../components/assets/images/PW.webp'; 
 
function MakeoverPW() {
    return (
        <div className='makeoverWrap'>
            <img src={PW} alt="Blank" />

            <div className='makeoverContent'>
                <h1>Witness the Remarkable Transformation of Your Property</h1>
                <hr />
                <span>
                At Picture Perfect Paint, we understand that a clean exterior is crucial for maintaining your property’s aesthetic appeal and value. Whether it’s house power washing or commercial power washing, our experts are trained to provide a thorough and deep clean, eradicating dirt, grime, and unwanted stains. We aim to refresh your outdoor spaces, enhancing the curb appeal of your property.
                </span>
                <br />
                <br />
                <h1>We provide the following:
                </h1>
                <span>
                    1. House Power Washing
                    <br />
                    2. Deck and Patio Power Washing
                    <br />
                    3. Driveway and Sidewalk Power Washing
                    <br />
                    4. Commercial Power Washing
                </span>

            </div>

              
        </div>

    );
}

export default MakeoverPW;