import React from "react";
import '../styles/whyTable.css';
import areaMap from '../components/assets/images/areaMap.png';

const WhyTable = () => {
    return (
        <div className="whyTable">
            <span>Why Go With Us?</span>
            <table>
                <tr>
                    <th>01</th>
                    <td className="TableGrey">We Care About Your Home</td>
                    <td className="description">We treat your home with the utmost care and respect as if it were our own. Our commitment to honesty ensures that we will always be transparent and truthful, valuing the trust you place in us to handle your property.</td>
                </tr>
                <tr>
                    <th>02</th>
                    <td className="TableGrey">We Keep Our Word</td>
                    <td className="description">We are committed to delivering on our promises, ensuring punctuality, and prioritizing open communication with our clients.</td>
                </tr>
                <tr>
                    <th>03</th>
                    <td className="TableGrey">Competitive Price</td>
                    <td className="description">Despite using high-quality products, our prices remain highly competitive. We are flexible in negotiations and offer tailored pricing to meet individual needs.</td>
                </tr>
                <tr>
                    <th>04</th>
                    <td className="TableGrey">Our Experience</td>
                    <td className="description">With over 5 years of experience, we are among the few contractors Sherwin Williams-certified. Our dedication to customer care and delivering unparalleled experience sets us apart in the industry.</td>
                </tr>
            </table>

            {/* <img className="areaMap" src={areaMap} data-lazy-src="https://www.pictureperfectpaintllc.com/wp-content/uploads/2023/07/Map-centered.webp" data-ll-status="loaded" class="entered lazyloaded"></img> */}
            <div className="areaMap"></div>
        </div>
    );
}

export default WhyTable;