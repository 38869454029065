import React from "react";
import "../styles/ha.css";

const HomeAbout = () => {
    return (
        <div className="HaWrap">
        <div className="HaHead">
        <div className="HaSub">
            <h2>Your Painting Authority</h2>
            <hr></hr>
        </div>
            <h1 className="head"> World-Class Painting Services in Indianapolis, IN</h1>
        </div>
        <div className="homeAbout">
            <div className="HaLeftContent">
                <h2>Transforming Indianapolis one brushstroke at a time.</h2>
                <span>Are you noticing peeling paint, stains on your walls, or an outdated color scheme? These are signs that your property may be crying out for a fresh coat of paint. Picture Perfect Paint is your trusted partner in Indianapolis, IN, for all your painting needs. We provide comprehensive painting services, from residential and commercial painting to additional services that help maintain and enhance your property’s value.</span>  
            </div>
            <div className="HaMiddleContent">
                <div className="HaTopMid">
                    <h2>Quality Craftsmanship</h2>
                    <span>We blend expertise with precision for superior results.</span>
                </div>
                <div className="HaBottomMid">
                    <h2>Comprehensive Solutions</h2>
                    <span>We provide a wide range of services to cater to all your property needs.</span>
                </div>
            </div>
            <div className="HaRightContent">
                <div className="HaTopRight">
                    <h2>Dependable Service</h2>
                    <span>Our team is dedicated to meeting timelines and exceeding expectations.</span>
                </div>
                <div className="HaBottomRight">
                    <h2>Customer Satisfaction</h2>
                    <span>Your satisfaction is our greatest accomplishment.</span>
                </div>
            </div>
        </div>
        </div>
    );
}

export default HomeAbout;