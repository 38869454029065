import React from 'react';
import house from '../components/assets/images/a-beautiful-house-with-large-front-yard-opt.webp';
import '../styles/Makover.css';
import Commercial from '../components/assets/images/commercial.webp'; 
 
function MakeoverCom() {
    return (
        <div className='makeoverWrap'>
            <img src={Commercial} alt="Blank" />

            <div className='makeoverContent'>
                <h1>Give Your Space the Makeover it Deserves</h1>
                <hr />
                <span>
                Each commercial setting is unique and requires a tailored approach. At Picture Perfect Paint, we understand this and specialize in transforming spaces to meet specific requirements. Whether it’s walls or ceilings, our commercial painting services cover all your painting needs. Our commercial painting contractor team is experienced in handling projects of varying scales, ensuring we deliver results that align with your vision. We transform your space, creating a fresh and lively atmosphere that enhances your business’s image and provides an appealing environment for your employees, customers, and visitors.
                </span>
                <br />
                <br />
                <h1>Elevate Your Business Environment</h1>
                <span>
                Are you ready to redefine your business space and enhance its aesthetics? Trust Picture Perfect Paint, the leading commercial painting services provider in Indianapolis, IN. Our dedicated team is ready to bring your vision to life, offering a range of solutions tailored to your specific needs. Give us a call to kickstart your commercial painting project today!
                </span>

            </div>

              
        </div>

    );
}

export default MakeoverCom;

